import { Box, Flex } from "@chakra-ui/react";
import { IWikifolioDetailProps } from "src/api/server/wf-detail.types";
import { Container } from "src/components/layout/container";
import { LeverageProductsModal } from "src/components/modals/leverage-products-modal/leverage-products-modal";
import { Ad } from "src/components/modules/ad-module/ad-module";
import { CertificateBox } from "src/components/pages/detail/certificate-box";
import { GENERAL_SECTION_SPACING } from "src/components/pages/detail/constants";
import { DownloadSection } from "src/components/pages/detail/download-section";
import { FeedSection } from "src/components/pages/detail/feed/feed-section";
import { JumpMarkAnchor, JumpMarksBar, JumpMarkSection } from "src/components/pages/detail/jump-marks";
import { EnumJumpMarkSection } from "src/components/pages/detail/jump-marks/consts";
import { JumpMarkAnchorRefsProvider } from "src/components/pages/detail/jump-marks/jump-mark-anchor-refs-provider";
import { KeyFigures } from "src/components/pages/detail/key-figures/key-figures";
import { NewsListSection } from "src/components/pages/detail/news/news-list-section";
import { PortfolioChartWrapper } from "src/components/pages/detail/portfolio-chart/portfolio-chart-wrapper";
import { PortfolioTableSection } from "src/components/pages/detail/portfolio-table-section/portfolio-table-section";
import { SalesSection } from "src/components/pages/detail/sales-section/sales-section";
import { SelectedWikifolios } from "src/components/pages/detail/selected-wikifolios";
import { HideContent } from "src/components/pages/detail/shared/hide-content";
import { TagSection } from "src/components/pages/detail/tag-section";
import { TopKpi } from "src/components/pages/detail/top-kpi/top-kpi";
import { TopSection } from "src/components/pages/detail/top-section";
import { TradeHistorySection } from "src/components/pages/detail/trade-history/trade-history-section";
import { TradingIdeaSection } from "src/components/pages/detail/trading-idea-section";
import { WikifolioDetailBenchmarkWrapper } from "src/components/pages/detail/wikifolio-detail-benchmark-wrapper";
import { LANGUAGE } from "src/consts/locale";
import { useRouterLocale } from "src/utils/router/use-router-locale";
import { TreemapChartSection } from "./treemap-chart/treemap-chart-section";
import { WatchlistCtaSection } from "./watchlist-cta";

interface IWikifolioDetailPage extends Pick<IWikifolioDetailProps, "data" | "dict" | "head"> {
    isLoggedIn: boolean;
    wikifolioId: string;
}

const MAIN_VIEW_WIDTH = ["auto", "670px", "604px", "752px"];

export const WikifolioDetailPage = ({ data, dict, head, isLoggedIn, wikifolioId }: IWikifolioDetailPage) => {
    const { language } = useRouterLocale();

    return (
        <>
            <Flex flexDirection="column" mb={[5, 10]}>
                <JumpMarkAnchorRefsProvider>
                    <Container order={["initial", -1]}>
                        <JumpMarkSection section={EnumJumpMarkSection.Overview}>
                            <TopSection
                                data={data}
                                headData={{ metaTitle: head.title, metaDescription: head.description, url: head.canonicalUrl }}
                                mt={[5, 5, 7]}
                                mb={[3, 4]}
                            />
                        </JumpMarkSection>
                    </Container>
                    <JumpMarksBar order={-1} dict={dict} mb={[0, 4]} />
                    <Container>
                        <Flex justifyContent="space-between" flexDirection={["column", "column", "row"]}>
                            <Box width={MAIN_VIEW_WIDTH}>
                                <JumpMarkSection section={EnumJumpMarkSection.Overview} overflow={["hidden", "visible"]}>
                                    {isLoggedIn ? (
                                        <WikifolioDetailBenchmarkWrapper
                                            wikifolioId={data.wikifolio.id}
                                            wikifolioStatus={data.wikifolio.status}
                                            creationDate={data.masterData.creationDate.value}
                                            baseCurrency={data.wikifolio.currency}
                                            dict={dict.benchmark}
                                            mb={[5, 5, 7]}
                                        />
                                    ) : (
                                        <HideContent
                                            imageSrc="/img/chart-hidden-content.png"
                                            height={["395px", "502px"]}
                                            headline={dict.hideContent.title}
                                            description={dict.hideContent.description}
                                        />
                                    )}
                                    <TopKpi keyFigures={data.keyFigures.topKpis} />
                                    <PortfolioChartWrapper
                                        isLoggedIn={isLoggedIn}
                                        wikifolioId={wikifolioId}
                                        dict={dict.portfolioChart}
                                        my={GENERAL_SECTION_SPACING}
                                    />
                                </JumpMarkSection>
                            </Box>
                            <Box as="aside" pos="relative" w={["auto", "auto", "278px", "290px"]} height={["auto", "auto", 0]} mb={[10, 10, 0]}>
                                <CertificateBox dict={dict.certificate} data={data} />
                                {data?.ad && (
                                    <Box pt={3} display={["none", "none", "block"]}>
                                        <Ad {...data.ad} />
                                    </Box>
                                )}
                            </Box>
                        </Flex>
                    </Container>

                    <Container>
                        <Box width={MAIN_VIEW_WIDTH}>
                            {(data?.tagsData || data?.salesContent) && (
                                <Box mb={[10, 10, 14]}>
                                    <JumpMarkSection section={EnumJumpMarkSection.Overview}>
                                        {data.salesContent && <SalesSection data={data.salesContent} mb={GENERAL_SECTION_SPACING} />}
                                        {data.tagsData && <TagSection data={data.tagsData} dict={dict.tagSection} />}
                                    </JumpMarkSection>
                                </Box>
                            )}

                            <Box mb={[10, 10, 14]}>
                                <JumpMarkAnchor section={EnumJumpMarkSection.Feed} />
                                <JumpMarkSection section={EnumJumpMarkSection.Feed}>
                                    <FeedSection wikifolioId={wikifolioId} dict={dict.feed} />
                                </JumpMarkSection>
                            </Box>
                        </Box>
                    </Container>

                    {data.watchlistCta.first && (
                        <WatchlistCtaSection
                            as="section"
                            wikifolioId={wikifolioId}
                            wikifolioShortDescription={data.wikifolio.shortDescription}
                            data={data.watchlistCta.first}
                        />
                    )}

                    <Container>
                        <Box width={MAIN_VIEW_WIDTH} mb={[10, 10, 14]}>
                            <Box mb={[10, 10, 14]}>
                                <JumpMarkAnchor section={EnumJumpMarkSection.TradingIdea} />
                                <JumpMarkSection section={EnumJumpMarkSection.TradingIdea}>
                                    <TradingIdeaSection
                                        dict={dict}
                                        isTranslationNecessary={data.isTranslationNecessary}
                                        description={data.wikifolio.description}
                                        masterData={data.masterData}
                                        investmentUniverseData={data.investmentUniverseData}
                                    />
                                </JumpMarkSection>
                            </Box>

                            {isLoggedIn && (
                                <>
                                    <Box mb={[10, 10, 14]}>
                                        <JumpMarkAnchor section={EnumJumpMarkSection.Portfolio} />
                                        <JumpMarkSection section={EnumJumpMarkSection.Portfolio}>
                                            <PortfolioTableSection fullName={data.wikifolio.fullName} dict={dict.portfolioTable} />
                                        </JumpMarkSection>
                                    </Box>

                                    {language === LANGUAGE.DE && (
                                        <Box mb={[10, 10, 14]}>
                                            <NewsListSection
                                                wikifolioId={wikifolioId}
                                                headline={dict.newsFeed.headline}
                                                emptyState={dict.newsFeed.empty}
                                            />
                                        </Box>
                                    )}

                                    <Box mb={[10, 10, 14]}>
                                        <JumpMarkAnchor section={EnumJumpMarkSection.KeyFigures} />
                                        <JumpMarkSection section={EnumJumpMarkSection.KeyFigures}>
                                            <KeyFigures keyFigures={data.keyFigures} dict={dict} />
                                        </JumpMarkSection>
                                    </Box>

                                    <TreemapChartSection
                                        wikifolioStatus={data.wikifolio.status}
                                        dict={dict.pnlChart}
                                        symbol={data.wikifolio.symbol}
                                        creationDate={data.masterData.creationDate.value}
                                    />
                                </>
                            )}
                        </Box>
                    </Container>
                    {data.watchlistCta.second && (
                        <WatchlistCtaSection
                            as="section"
                            wikifolioId={wikifolioId}
                            wikifolioShortDescription={data.wikifolio.shortDescription}
                            data={data.watchlistCta.second}
                        />
                    )}
                    <Container>
                        <Box width={MAIN_VIEW_WIDTH}>
                            {isLoggedIn && (
                                <>
                                    <Box mb={[10, 10, 14]}>
                                        <JumpMarkAnchor section={EnumJumpMarkSection.Trades} />
                                        <JumpMarkSection section={EnumJumpMarkSection.Trades}>
                                            <TradeHistorySection wikifolioId={wikifolioId} dict={dict.tradeHistoryTable} />
                                        </JumpMarkSection>
                                    </Box>

                                    {/* Dev-Note: there is no separate jumpmark section for the download area, therefore we keep it under the last section */}
                                    <JumpMarkSection section={EnumJumpMarkSection.Trades}>
                                        <DownloadSection data={data} dict={dict.downloadArea} />
                                    </JumpMarkSection>
                                </>
                            )}

                            {!isLoggedIn && (
                                <>
                                    <JumpMarkAnchor section={EnumJumpMarkSection.Portfolio} />
                                    <JumpMarkSection section={EnumJumpMarkSection.Portfolio} />
                                    <JumpMarkAnchor section={EnumJumpMarkSection.KeyFigures} />
                                    <JumpMarkSection section={EnumJumpMarkSection.KeyFigures} />

                                    <JumpMarkAnchor section={EnumJumpMarkSection.Trades} />
                                    <JumpMarkSection section={EnumJumpMarkSection.Trades} />

                                    <HideContent
                                        imageSrc="/img/portfolio-hidden-content.png"
                                        height={["815px", "1066px", "988px", "1022px"]}
                                        headline={dict.hideContent.title}
                                        description={dict.hideContent.description}
                                        wikifolioId={wikifolioId}
                                    />
                                </>
                            )}
                        </Box>
                    </Container>
                </JumpMarkAnchorRefsProvider>
            </Flex>

            <SelectedWikifolios headline={dict.selectWikifolioTitle} selectedWikifolios={data.selectedWikifolios} />
            <LeverageProductsModal containsLeverageProducts={data.wikifolio.containsLeverageProducts} />
        </>
    );
};
