import { BoxProps, Flex } from "@chakra-ui/react";
import { IWikifolioDetailData, IWikifolioDetailDict } from "src/api/server/wf-detail.types";
import { Section } from "src/components/base";
import { WfHeading } from "src/components/base/wf-heading";
import { PopoverButtonIconInfo, WfPopover } from "src/components/base/wf-popover";
import { Tag } from "src/components/common/tag/tag";
import { EnumTagColor } from "src/types/common-enum";

const TAG_SECTION_TEST_ID = "tag-section";

interface IProps extends BoxProps {
    dict: IWikifolioDetailDict["tagSection"];
    data: IWikifolioDetailData["tagsData"];
}

export const TagSection = ({ dict, data, ...boxProps }: IProps) => {
    return (
        <Section {...boxProps} data-test-id={TAG_SECTION_TEST_ID}>
            <Flex align="center" mb={3}>
                <WfHeading as="h2" fontSize={"2xl"}>
                    {dict.title}
                </WfHeading>
                <WfPopover {...dict.tooltip}>
                    <PopoverButtonIconInfo ariaLabel={dict.title} ml={1} />
                </WfPopover>
            </Flex>
            <Flex flexWrap="wrap" gap={2}>
                {data.rewards.map((tag, i) => (
                    <Tag key={"r" + i} colorScheme={EnumTagColor.Green} {...tag} />
                ))}
                {data.tradings.map((tag, i) => (
                    <Tag key={"t" + i} colorScheme={EnumTagColor.Lilac} {...tag} />
                ))}
                {data.investmentHorizon.map((tag, i) => (
                    <Tag key={"ih" + i} colorScheme={EnumTagColor.Blue} {...tag} />
                ))}
                {data.investmentRegion.map((tag, i) => (
                    <Tag key={"ir" + i} colorScheme={EnumTagColor.Orange} {...tag} />
                ))}
                {data.basics.map((tag, i) => (
                    <Tag key={"b" + i} colorScheme={EnumTagColor.Gray} {...tag} />
                ))}
                {data.bvhChallenge.map((tag, i) => {
                    return <Tag key={"bch" + i} colorScheme={EnumTagColor.Blush} {...tag} />;
                })}
            </Flex>
        </Section>
    );
};
