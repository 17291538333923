import { HStack, Skeleton, VStack } from "@chakra-ui/react";
import { CHART_HEIGHT } from "./constants";

export const TreemapChartSkeleton = ({ ...boxProps }) => {
    return (
        <VStack width="100%" align="flex-start" height={CHART_HEIGHT} {...boxProps} gap="2px" overflow="hidden">
            <HStack spacing="2px">
                <Skeleton h="300px" w="300px" />
                <Skeleton h="300px" w="200px" />
                <Skeleton h="300px" w={13} />
                <VStack spacing="2px">
                    <Skeleton h="150px" w={13} />
                    <Skeleton h="100px" w={13} />
                    <Skeleton h="46px" w={13} />
                </VStack>
                <VStack spacing="2px">
                    <Skeleton h="150px" w={13} />
                    <Skeleton h="100px" w={13} />
                    <Skeleton h="46px" w={13} />
                </VStack>
            </HStack>
            <HStack spacing="2px" align="flex-start">
                <VStack w="100%" gap="2px">
                    <Skeleton h="300px" w="300px" />
                </VStack>
                <VStack w="100%" gap="2px">
                    <Skeleton h="100px" w="150px" />
                    <Skeleton h="150px" w="150px" />
                </VStack>
                <VStack w="100%" gap="2px">
                    <Skeleton h="100px" w={13} />
                    <Skeleton h="150px" w={13} />
                </VStack>

                <VStack w="100%" gap="2px">
                    <Skeleton h="80px" w="120px" />
                    <Skeleton h="80px" w="120px" />
                    <Skeleton h="90px" w="120px" />
                </VStack>
                <VStack w="100%" gap="2px">
                    <Skeleton h="80px" w="80px" />
                    <Skeleton h="80px" w="80px" />
                    <Skeleton h="90px" w="80px" />
                </VStack>
            </HStack>
        </VStack>
    );
};
